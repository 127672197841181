<mat-card>
  <mat-card-content>
    <div style="display: flex; justify-content: center; align-items: center">
      <img src="../../../assets/image/edulab.png" width="320px" height="150px" alt="Edulab Logo">
    </div>
    <div class="login-container">
      <h1 style="text-align: center;">Register to Communication</h1>
      <div *ngIf="messageAlert" [ngClass]="'alert alert-' + alertColor + ' custom-alert'" role="alert" style="width: 100%;">
        <h4 class="text-center"><b>{{title}}</b></h4>
        <p class="text-center">{{message}}</p>
      </div>
      <form [formGroup]="registerForm" novalidate (ngSubmit)="registerUser()">
        <mat-form-field appearance="outline" class="curved-input" floatLabel="always">
          <mat-label>Email</mat-label>
          <input matInput type="email" ngModel email placeholder="Enter your email" formControlName="email">
          <mat-error
            *ngIf="registerForm.get('email').hasError('required') && (registerForm.get('email').touched || registerForm.get('email').dirty)">
            Email is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="curved-input" floatLabel="always">
          <mat-label>Username</mat-label>
          <input matInput placeholder="Enter your username" formControlName="username">
          <mat-error
            *ngIf="registerForm.get('username').hasError('required') && (registerForm.get('username').touched || registerForm.get('username').dirty)">
            Username is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="curved-input" floatLabel="always">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="passwordVisible ? 'text' : 'password'" placeholder="Enter your password"
          formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error
          *ngIf="registerForm.get('password').hasError('required') && (registerForm.get('password').touched || registerForm.get('password').dirty)">
          Password is required
        </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" class="login-button curved-button" type="submit">Register</button>
        <div class="register-link">
          <p>Already have an account? <a (click)="redirectToLogin()" style="cursor: pointer; color: blue; font-weight: 500">Login</a></p>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
