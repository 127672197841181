import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { LoggedinService } from './logged-in.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private loggedInService: LoggedinService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    if(this.loggedInService.isLoggedIn.value){
    return this.loggedInService.isLoggedIn$;
}else {
  // Check if authentication status is stored in localStorage
  const storedAuthStatus = localStorage.getItem('isLoggedIn');
  if (storedAuthStatus === 'true') {
    this.loggedInService.login();
    return this.loggedInService.isLoggedIn$;
  } else {
    this.router.navigate(['/login']);
    return of(false);
  }
}
  }

  checkLogin(url: string): Observable<boolean> {
    /* if (this.loggedInService.isLoggedIn) {
      return true;
    } else {
      this.loggedInService.redirectUrl = url;
      return false;
    } */
    return this.loggedInService.isLoggedIn$;
  }
}
