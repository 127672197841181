<div class="container-fluid page">
  <div class="d-flex page__box p-3 mt-2">Settings</div>
  <div class="page__content shadow p-3 position-relative">

    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="searchTerm" (keyup)="applyFilter($event)" placeholder="Provider Name" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

        <!-- Id Column -->
        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef>  Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Email Id Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email Id </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

      <!-- Email Verified Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell *matCellDef="let element"> {{element.role}} </td>
        </ng-container>

        <!-- Email Verified Column -->
        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef> Email Verified </th>
          <td mat-cell *matCellDef="let element"> {{element.isEmailVerified}} </td>
        </ng-container>

            <!-- Password Column -->
        <ng-container matColumnDef="reset_password">
          <th mat-header-cell *matHeaderCellDef> Reset Password </th>
          <td mat-cell *matCellDef="let element" class="password-cell password-column">
            <span class="password-text">{{ element.showPassword ? element.reset_password : '******' }}</span>
            <button mat-icon-button class="show-password-button" (click)="togglePasswordVisibility(element)">
              <mat-icon>{{ element.showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator #paginator
      class="demo-paginator"
      (page)="handlePageEvent($event)"
      [length]="count"
      [pageSize]="pageSize"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
    </div>
  </div>
</div>
