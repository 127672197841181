<mat-card>
  <mat-card-content>
    <div style="display: flex; justify-content: center; align-items: center">
      <img src="../../../assets/image/edulab.png" width="320px" height="150px" alt="Edulab Logo">
    </div>
    <div class="login-container">
      <h1 style="text-align: center;">Login to Communication</h1>
      <div *ngIf="messageAlert" [ngClass]="'alert alert-' + alertColor + ' custom-alert'" role="alert" style="width: 100%;">
        <h4 class="text-center"><b>{{title}}</b></h4>
        <p class="text-center">{{message}}</p>
      </div>
      <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="curved-input" floatLabel="always">
          <mat-label>Email</mat-label>
          <input matInput type="text" ngModel email placeholder="Enter your email" formControlName="usernameOrEmail">
          <mat-error
            *ngIf="loginForm.get('usernameOrEmail').hasError('required') && (loginForm.get('usernameOrEmail').touched || loginForm.get('usernameOrEmail').dirty)">
            Email is required
          </mat-error>
        </mat-form-field>
 
        <mat-form-field appearance="outline" class="curved-input">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="passwordVisible ? 'text' : 'password'" placeholder="Enter your password"
          formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error
          *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').touched || loginForm.get('password').dirty)">
          Password is required
        </mat-error>
        </mat-form-field>
        <div class="remember-forgot">
          <a (click)="redirectToPath('forgotPassword')">Forgot Password?</a>
        </div>
        <button mat-raised-button color="primary" class="login-button curved-button" type="submit">Login</button>
        <br>
        <!-- <div class="register-link">
          <p>Don't have an account? <a (click)="redirectToPath('register')" style="cursor: pointer; color: green; font-weight: 500">Register Here</a></p>
        </div> -->
      </form>
    </div>
  </mat-card-content>
</mat-card>
