import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';




@Component({
  selector: 'app-activity-dialog',
  template: `
  <h1 mat-dialog-title>Email Information</h1>
  <mat-dialog-content class="mat-typography">
<h2>Details</h2>
<br>    
<div>
<div class="row" style="border: 1px solid #ccc; padding: 13px;">
  <div class="col-md-3">
    <span style="font-weight: bold; font-size: 16px;" >TO</span>
  </div>
  <div class="col-md-9" style="font-size:16px;">
    {{ data.to }}
  </div>
</div>


<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
  <span style="font-weight: bold; font-size: 16px;">From</span>
</div>
<div class="col-md-9" style="font-size:16px;">
  {{ data.from }}
</div>
</div>

<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">Subject</span>
</div>
<div class="col-md-9" style="font-size:16px;" >
  {{ data.subject }}
</div>
</div>

<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">CC</span>
</div>
<div class="col-md-9" style="font-size:16px;" >
  {{ data.cc }}
</div>
</div>

<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">BCC</span>
</div>
<div class="col-md-9" style="font-size:16px;" >
  {{ data.bcc }}
</div>
</div><br><br><br>

<h2>Event History</h2>
<div class="row" style="border: 1px solid #ccc; padding: 13px;">
  <span style="font-weight: bold; font-size: 16px;">Received by Sendgrid</span>
</div>

<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">Processed</span>
</div>
<div class="col-md-9" style="font-size:14px;" >
  {{ data.shootTime }}
</div>
</div>

<br>
<div class="row" style="border: 1px solid #ccc; padding: 12px;">
  <span style="font-weight: bold; font-size: 16px;">Received by Sendgrid</span>
</div>
<div class="row" style="border: 1px solid #ccc; padding: 10px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">{{data.status}}</span>
</div>
<div class="col-md-9" style="font-size:14px;" >
  {{ data.shootTime }}
</div>
</div>
<br>
<br>
<div class="row" style="border: 1px solid #ccc; padding: 12px;">
  <span style="font-weight: bold; font-size: 16px;">Received by {{data.from}}</span>
</div>

<div class="row" style="border: 1px solid #ccc; padding: 13px;">
<div class="col-md-3">
<span style="font-weight: bold; font-size: 16px;">Opened</span>
</div>
<div class="col-md-9" style="font-size:14px;" >
  {{ data.viewTime }}
</div>
</div>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Cancel</button>
  </mat-dialog-actions>
  `,
  styles: [
    `.custom-dialog-content {
  width: 50px ; /* Set your desired width for the content */
  height: 50px; /* Set your desired height for the content */
  overflow: auto; /* Enable scrolling within the content if necessary */
}`

  ]
})
export class ActivityDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log('Received data in dialog:', this.data);
  }
}
