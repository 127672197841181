import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggedinService } from 'src/app/logged-in.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  isLoggedIn: boolean;
  passwordVisible: boolean = false;
  message: string = '';
  messageAlert: boolean = false;
  forgotPasswordForm = this.fb.group({
    email: ['', Validators.required],
  });
  title: string;
  alertColor: string;
  hide: boolean = true;
  showToast: boolean = false;
  isLoading: boolean = false;
  showAlert: boolean = false;
  alertMessage: string = '';
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public loggedInService: LoggedinService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  resetPassword() {
    const email = this.forgotPasswordForm.controls['email'].value;
    if (email) {
      this.isLoading = true;
      this.userService.forgotPassword({ email: email }).subscribe(
        (response: any) => {
          if (response && response.status === 'ok') {
            this.showToast = true;
          } else {
            this.showAlert = true;
            this.showToast = false;
            this.alertMessage = response.message;
          }
          this.isLoading = false;
        },
        (error) => {
          this.showToast = false;
          this.isLoading = false;
          this.showAlert = true;
          this.alertMessage = error.error.message;
        }
      );
    }
  }

  goBack(){
    this.router.navigate(['/login']);
  }
}
