  <!-- Toolbar -->

  <router-outlet></router-outlet>

  <!-- <mat-toolbar color="primary" class="app-header">
    <div class="logo"><a href="" class="positronx">Edulab</a></div>
    <div class="user">
      <div class="user-name">{{username}}</div>
      <div class="user-image">
        <a mat-button [matMenuTriggerFor]="beforeMenu">{{shortUserName}}</a>
        <mat-menu #beforeMenu="matMenu" xPosition="after">
          <button mat-menu-item routerLink="login" routerLinkActive="active">Log Out</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar> -->
