import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { User, RegisterDetails, forgotPasswordData } from './user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User;
  private userData:any;

  constructor(private http: HttpClient) {}

  public login(user: User): Observable<any> {
    const url = environment.apiUrl+'api/dashboard/login';
    return this.http.post<any>(url, user);
  }

  public register(regData: RegisterDetails): Observable<any> {
    const url = environment.apiUrl+'api/dashboard/register';
    return this.http.post<any>(url, regData);
  }

  public forgotPassword(forgotPassData: forgotPasswordData): Observable<any> {
    const url = environment.apiUrl+'v2/auth/forgot-password';
    return this.http.post<any>(url, forgotPassData);
  }

  setUserData(data: any, userId : string) {
    this.userData = { data, userId };
    localStorage.setItem('userData', JSON.stringify(this.userData));
  }

  getUserData() {
    return this.userData;
  }

  getAllUserData(userId: string): Observable<any> {
    const url = environment.apiUrl+'api/user/getUser';
    const params = { userId: userId.toString() };
    return this.http.get<any>(url, { params });
  }

  logout() {
    this.userData = null; // Clear data in memory
    localStorage.removeItem('userData'); // Remove data from localStorage
  }
}
