<mat-card class="example-card">
  <mat-card-content>
    <div class="forgot-password-header">
        <h2><i class="fa fa-lock fa-4x"></i></h2>
        <button class="back-to-login-btn" mat-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <h1 class="text-center">Forgot Password?</h1>
        <p>You can reset your password here.</p>
        <br>
        <div *ngIf="showToast === false && isLoading === false" class="emailBox">
          <form [formGroup]="forgotPasswordForm">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Email</mat-label>
              <input matInput type="email" placeholder="Enter your email address" formControlName="email">
              <mat-error
                *ngIf="forgotPasswordForm.get('email').hasError('required') && (forgotPasswordForm.get('email').touched || forgotPasswordForm.get('email').dirty)">
                Email is required
              </mat-error>
            </mat-form-field>
          </form>
        </div>
        <div class="toastBox">
          <div *ngIf="showToast === true && isLoading === false">
            <h3>We sent you an email to reset your password.</h3>
            <p>If you haven't received the email within a few minutes, please check your spam folder.</p>
          </div>
          <div *ngIf="showAlert === true && showToast === false && isLoading === false">
            <h4 style="color: red;">{{alertMessage}}</h4>
          </div>
        </div>
        <div *ngIf="isLoading === true" style="width: 100%;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <br>
        </div>

      <button *ngIf="showToast === false && isLoading === false" mat-raised-button color="primary" type="button" (click)="resetPassword()" [disabled]="isLoading">
        Submit
      </button>
      <button *ngIf="showToast === true" mat-raised-button color="primary" type="button" (click)="goBack()">
        Done
      </button>
    </div>
  </mat-card-content>

</mat-card>
