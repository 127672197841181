<div style="margin:30px; display: flex; align-items: center; justify-content: space-between;">
    <div>
        <p><span style="font-size: 50px; color:#0a2351;">Message Activity Feed</span></p>
    </div>
</div>

<mat-card>
    <mat-card-content></mat-card-content>
    <!--MobileNumber Search-->
    <mat-form-field style="margin-left:50px;">
        <mat-label>Phone Number</mat-label>
        <input matInput placeholder="Search with Phone Number" #phoneNo>
    </mat-form-field>

    <!--ApplicationName Search-->
    <mat-form-field style="margin-left:100px;">
        <mat-label>Application Name</mat-label>
        <input matInput placeholder="Search with Application Name" #applicationName>
    </mat-form-field>

    <button mat-raised-button style="margin-left: 70px;"
        aria-label="Button that displays a tooltip in various positions"
        (click)="search(phoneNo.value,applicationName.value)">Search
    </button>
</mat-card>

<div *ngIf=isSearchButtonClicked>
    <div class="d-flex justify-content-center">
        <div class="spinner-border text-danger" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource">

        <!-- To Column -->
        <ng-container matColumnDef="PhoneNo">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;">Phone No</th>
            <td mat-cell *matCellDef="let element"> {{element.to}} </td>
        </ng-container>

        <!-- Subject Column -->
        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Message </th>
            <td mat-cell *matCellDef="let element"> {{element.msg}} </td>
        </ng-container>

        <!-- Lastseen Column -->
        <ng-container matColumnDef="shootTime">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;">shoot Time</th>
            <td mat-cell *matCellDef="let element"> {{element.shootTime}} </td>
        </ng-container>

        <!-- failCount Column -->
        <ng-container matColumnDef="failCount">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Fail Count </th>
            <td mat-cell *matCellDef="let element"> {{element.failCount}} </td>
        </ng-container>

        <!--  failTime Column -->
        <ng-container matColumnDef="failTime">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Fail Time </th>
            <td mat-cell *matCellDef="let element"> {{element.failTime}} </td>
        </ng-container>

        <!--  ApplicationName Column -->
        <ng-container matColumnDef="ApplicationName">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Application Name </th>
            <td mat-cell *matCellDef="let element"> {{element.application_name}} </td>
        </ng-container>

        <!-- Define Header and Data Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="center-text" *ngIf="showData">
    <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/filled-speech-bubble-with-dots--v1.png"
        alt="filled-speech-bubble-with-dots--v1" />
    <p>{{ displayText }}</p>
</div>

<!--Pagination -->
<mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="count" [pageSize]="pageSize"
    [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
    [showPageSizeOptions]="showPageSizeOptions ? pageSizeOptions:[]" [hidePageSize]="hidePageSize"
    [pageIndex]="pageIndex" aria-label="Select Page">
</mat-paginator>