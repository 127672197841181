import { Component, OnInit, ViewChild,AfterViewInit  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BackendService } from '../../backend.service'
import { UserService } from '../../user.service'
import { ActivatedRoute } from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  position: number;
  username: string;
  password: string;
  showPassword: boolean;
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})

export class ConfigurationComponent implements OnInit {
  username: string;
  shortUserName: string;
  displayedColumns: string[] = ['Id','email', 'name', 'role', 'verified','reset_password'];
  dataSource : MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  getallUserData: any;

  constructor(private fb: FormBuilder,
  private userService : UserService,
  private router : Router, private route: ActivatedRoute,
  private backendService : BackendService
  
  )
   {
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  }
  searchTerm: string = '';

  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  count:number;

  pageEvent: PageEvent;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getUserData('',this.pageIndex + 1,);
  }

  ngOnInit(): void {

    this.getUserData('',1)
    this.route.queryParams.subscribe(params => {
      this.username = params['username'];
      if (this.username) {
        this.username = this.username.charAt(0).toUpperCase() + this.username.slice(1);
      }
      if (this.username && this.username.length >= 2) {
        this.shortUserName = this.username.charAt(0).toUpperCase() + this.username.charAt(this.username.length - 1).toUpperCase();
      } else {
        this.shortUserName = this.username;
      }
    });
  }


  getUserData(searchTerm:string,page:number){
    this.backendService.getUserData(searchTerm,page).subscribe((data) => {
    this.dataSource= data['data'].data
    this.count=data['data'].count;
  });
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.getUserData(this.searchTerm, 1);
  }

  togglePasswordVisibility(user: PeriodicElement): void {
    user.showPassword = !user.showPassword;
  }
}