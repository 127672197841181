<div class="container-fluid page">
  <div class="d-flex page__box p-3 mt-2">Dashboard</div>
  <div class="page__content shadow p-3 position-relative">
    <div class="main-container">
      <div class="main">
        <!-- R1C1 Part -->
        <mat-card class="example-card">
          <div class="datePickerR1C1">

        <!--Date Range Search-->
            <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" ng-model-options="{timezone: 'utc'}">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix  [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>

          <!--Month Year Search-->
          <mat-form-field style="margin-left:30px;" >
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="selectedMonth"><mat-hint>MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"
              panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
      
            <button mat-raised-button [disabled]="range.invalid" style="margin-left: 20px;"
              matTooltip="Select Date Range to see the total number of emails, messages, whatsapp messages"
              [matTooltipPosition]="position.value!" aria-label="Button that displays a tooltip in various positions"
              (click)="DateRange();searchMonth()">Search </button>
          </div>
          <br>
          <div class="r1c1Container">
            <mat-card class="imageHolder" style="margin: 10px">
              <mat-card-content>
                <img class = "messageLogo" src="../../../assets/image/email.webp" alt="">
              <!-- <mat-card-title>Total Emails Delivered</mat-card-title> -->
                <mat-card-title><b>{{totalEmailsR1C1}}</b></mat-card-title>
              </mat-card-content>
            </mat-card>
            <mat-card class="imageHolder" style="margin: 10px">
              <mat-card-content>
                <img class = "messageLogo" src="../../../assets/image/message.png" alt="">
                <!-- <mat-card-title>Total Messages Delivered</mat-card-title> -->
                <mat-card-title><b>{{totalMessagesR1C1}}</b></mat-card-title>
              </mat-card-content>
            </mat-card>
            <mat-card class="imageHolder" style="margin: 10px">
              <mat-card-content>
                  <img class = "messageLogo" src="../../../assets/image/WhatsApp.svg" alt="">
                  <!-- <mat-icon style="margin-left: 10px; vertical-align: middle;"> textsms</mat-icon> -->
                <mat-card-title><b>{{totalWhatsappMessagesR1C1}}</b></mat-card-title>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-card>

        <div class="basic-container">
          <mat-tab-group mat-align-tabs="center" animationDuration="0ms" backgroundColor="primary" dynamicHeight
          [selectedIndex]="selected.value" (selectedIndexChange)="tabClick($event)">
            <!-- R1C2 Part -->
            <mat-tab label="Details Of Each Portal">
              <mat-card style="text-align: center;">
                <mat-card-body style="text-align: center;">
                  <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="rangeR1C2" [rangePicker]="picker2">
                      <input matStartDate formControlName="start" placeholder="Start date" ng-model-options="{timezone: 'utc'}">
                      <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker2></mat-date-range-picker>

                    <mat-error *ngIf="rangeR1C2.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="rangeR1C2.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>
                  <button mat-raised-button [disabled]="rangeR1C2.invalid" style="margin-left: 10px; margin-bottom: 10px"
                    matTooltip="Select Date Range to see the full analytics of each portal" [matTooltipPosition]="position.value!"
                    aria-label="Button that displays tooltip in various positions" (click)="DateRange1()">search </button>

                      <div class="r1c2AllCharts" *ngIf="chartR1C2Boolean == true">
                        <div class="r1c2MainChart" >
                          <canvas id="chart1"></canvas>
                        </div>
                        <div class="r1c2ClickedCharts">
                          <div>
                            <canvas id="chartEmailR1C2"></canvas>
                          </div>
                          <div>
                            <canvas id="chartMessageR1C2"></canvas>
                          </div>
                          <div>
                            <canvas id="chartWhatsappR1C2"></canvas>
                          </div>
                        </div>
                      </div>
                    <div class="row justify-content-center" *ngIf="noChartR1C2Boolean == true" style="margin-top: 25px">
                      <p>NO DATA AVAILABLE</p>
                      <img style="height: 200px; width: 225px;" src="../../../assets/image/Nodata1.png" alt="No Data Icon">
                    </div>
                </mat-card-body>
              </mat-card>
            </mat-tab>
            <!-- R1C3 Part -->
            <mat-tab label="Top Portals">
              <mat-card style="text-align: center;" class="mat-card-chart">
                <mat-card-body style="text-align: center;">
                  <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="rangeR1C3" [rangePicker]="picker3">
                      <input matStartDate formControlName="start" placeholder="Start date">
                      <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker3></mat-date-range-picker>

                    <mat-error *ngIf="rangeR1C3.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="rangeR1C3.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>
                  <button mat-raised-button [disabled]="rangeR1C3.invalid" matTooltip="Select Date Range to see the top portals" style="margin-left: 10px;"
                    [matTooltipPosition]="position.value!" aria-label="Button that displays a tooltip in various positions"
                    (click)="DateRange2()">search </button>

                  <div class="mainR1C3Container" *ngIf="chartR1C3Boolean == true">
                    <div class="mainR1C3Chart" style="width: 100%">
                      <canvas id="chart2"></canvas>
                    </div>
                  </div>
                  <div class="row justify-content-center" *ngIf="noChartR1C3Boolean == true" style="margin-top: 25px;">
                    <p>NO DATA AVAILABLE</p>
                    <img style="height: 200px; width: 225px" src="../../../assets/image/Nodata1.png" alt="No Data Icon">
                  </div>
                </mat-card-body>
              </mat-card>
            </mat-tab>
            <!-- R2C1 Part -->
            <mat-tab label="Total Failures">
              <mat-card style="text-align: center;" class="mat-card-chart">
                <mat-card-body style="text-align: center;">
                  <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="rangeR2C1" [rangePicker]="picker4">
                      <input matStartDate formControlName="start" placeholder="Start date" ng-model-options="{timezone: 'utc'}">
                      <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker4></mat-date-range-picker>
                    <mat-error *ngIf="rangeR2C1.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="rangeR2C1.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>
                  <button mat-raised-button [disabled]="rangeR2C1.invalid"
                    matTooltip="Select Date Range to see the total number of failures" [matTooltipPosition]="position.value!" style="margin-left: 10px;"
                    aria-label="Button that displays a tooltip in various positions" (click)="dateRangeR2C1()">search </button>

                    <div class="R2C1MainContainer" *ngIf="chartR2C1Boolean == true">
                      <div class="r2c1MainChart">
                        <canvas id="chart4"></canvas>
                      </div>
                      <div class="r2c1ClickedCharts">
                        <div>
                          <canvas id="chartEmailR2C1"></canvas>
                        </div>
                        <div>
                          <canvas id="chartMessageR2C1"></canvas>
                        </div>
                        <div>
                          <canvas id="chartWhatsappR2C1"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center" *ngIf="noChartR2C1Boolean == true" style="margin-top: 25px">
                      <p>NO DATA AVAILABLE</p>
                      <img style="height: 200px; width: 225px" src="../../../assets/image/Nodata1.png" alt="No Data Icon">
                    </div>
                </mat-card-body>
              </mat-card>
            </mat-tab>
            <!-- R2C2 Part -->
            <mat-tab label="Top Providers">
              <mat-card style="text-align: center;" class="mat-card-chart">
                <mat-card-body style="text-align: center;">
                  <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="rangeR2C2" [rangePicker]="picker5">
                      <input matStartDate formControlName="start" placeholder="Start date" ng-model-options="{timezone: 'utc'}">
                      <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker5></mat-date-range-picker>

                    <mat-error *ngIf="rangeR2C2.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="rangeR2C2.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>
                  <button mat-raised-button [disabled]="rangeR2C2.invalid"
                    matTooltip="Select Date Range to see the top providers for each portal" [matTooltipPosition]="position.value!" style="margin-left: 10px;"
                    aria-label="Button that displays a tooltip in various positions" (click)="dateRangeR2C2()">search </button>

                    <div class="R2C2MainContainer" *ngIf="chartR2C2Boolean == true">
                      <div class="r2c2MainChart">
                        <canvas id="chart5"></canvas>
                      </div>
                      <div class="r2c2ClickedCharts">
                        <div>
                          <canvas id="chartEmailR2C2"></canvas>
                        </div>
                        <div>
                          <canvas id="chartMessageR2C2"></canvas>
                        </div>
                        <div>
                          <canvas id="chartWhatsappR2C2"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center" *ngIf="noChartR2C2Boolean == true" style="margin-top: 25px;">
                      <p>NO DATA AVAILABLE</p>
                      <img style="height: 200px; width: 225px" src="../../../assets/image/Nodata1.png" alt="No Data Icon">
                    </div>
                </mat-card-body>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>

      </div>
    </div>
  </div>
</div>
