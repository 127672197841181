<div style="margin:30px; display: flex; align-items: center; justify-content: space-between;">
  <div>
    <p><span style="font-size: 50px; color:#0a2351;">Activity Feed</span></p>
  </div>
  <div style="margin-left: 30px;">
    <button mat-raised-button color="primary"
      (click)="download(email.value,startDate.value,endDate.value,subject.value,provider.value)">Export CSV</button>
  </div>
</div>


<div (keyup.enter)="search(email.value,startDate.value,endDate.value,subject.value,provider.value)">
  <mat-card>
    <mat-card-content></mat-card-content>

    <!--Email Search-->
    <mat-form-field style="margin-left:30px;">
      <mat-label>Email Id</mat-label>
      <input matInput placeholder="Search with emailId" #email>
    </mat-form-field>

    <!--Date Search-->
    <mat-form-field style="margin-left: 50px;">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" #startDate ng-model-options="{timezone: 'utc'}">
        <input matEndDate placeholder="End date" #endDate>
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <!--Subject Search-->
    <mat-form-field style="margin-left:30px;">
      <mat-label>Subject</mat-label>
      <input matInput placeholder="Search with Subject" #subject>
    </mat-form-field>

    <!--Provider Search-->
    <mat-form-field style="margin-left:30px;">
      <mat-label>Providers Name</mat-label>
      <input matInput placeholder="Search with Providers Name" #provider>
    </mat-form-field>

    <!-- Search btn -->
    <button mat-raised-button style="margin-left: 50px;"
      aria-label="Button that displays a tooltip in various positions"
      (click)="search(email.value,startDate.value,endDate.value,subject.value,provider.value)">Search</button>
  </mat-card>
</div>
<br>

<div *ngIf="isSearchButtonClicked">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-info" role="status" *ngIf="isLoading">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- To Column -->
    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> To </th>
      <td mat-cell *matCellDef="let element"> {{element.to}} </td>
    </ng-container>

    <!-- Subject Column -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Subject </th>
      <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
    </ng-container>

    <!-- Lastseen Column -->
    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;">Providers Name</th>
      <td mat-cell *matCellDef="let element"> {{element.provider}} </td>
    </ng-container>

    <!-- failCount Column -->
    <ng-container matColumnDef="failCount">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Fail Count </th>
      <td mat-cell *matCellDef="let element"> {{element.failCount}} </td>
    </ng-container>

    <!--  failTime Column -->
    <ng-container matColumnDef="failTime">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Fail Time </th>
      <td mat-cell *matCellDef="let element"> {{element.failTime}} </td>
    </ng-container>

    <!--  ApplicationName Column -->
    <ng-container matColumnDef="ApplicationName">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px;"> Application Name </th>
      <td mat-cell *matCellDef="let element"> {{element.ApplicationName}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" style="font-weight: 500;font-size: 15px;">No data matching the filter
        "{{email.value}}"</td>
    </tr>

  </table>
</div>

<div class="center-text" *ngIf="showData">
  <i class="fa-solid fa-envelope-circle-check"></i>
  <p>{{ displayText }}</p>
</div>

<!--Pagination -->
<mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="count" [pageSize]="pageSize"
  [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
  [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="pageIndex"
  aria-label="Select page">
</mat-paginator>