import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggedinService } from 'src/app/logged-in.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  isLoggedIn: boolean;
  passwordVisible = false;
  message: string = '';
  messageAlert: boolean = false;
  registerForm = this.fb.group({
    email: ['', Validators.required],
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  title: string;
  alertColor: string;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public loggedInService: LoggedinService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  registerUser() {
    const email = this.registerForm.controls['email'].value;
    const username = this.registerForm.controls['username'].value;
    const password = this.registerForm.controls['password'].value;
    try {
      this.userService
      .register({ emailId: email, username: username, password: password })
      .subscribe((response: any) => {
        if (response.status === 201) {
          this.messageAlert = true;
          this.alertColor = 'success';
          this.title = 'Hooray!';
          this.message = response.message;
          this.loggedInService.login().subscribe((isLoggedIn) => {
            setTimeout(() => {
              this.messageAlert = false;
              this.isLoggedIn = isLoggedIn;
              this.router.navigate(['/dashboard']);
            }, 1500);
            this.userService.setUserData(response.data, response.data.id);
          });
        } else {
          this.messageAlert = true;
          this.alertColor = 'danger';
          this.title = 'Oh Snap!';
          this.message = response.message;
          this.isLoggedIn = false;
          setTimeout(() => {
            this.messageAlert = false;
          }, 3000);
        }
      });
    } catch (error) {
      console.log("An error occurred: " + error);
    }

  }
}
