import { BrowserModule } from '@angular/platform-browser';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

/* Angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Angular Flex Layout */
import { FlexLayoutModule } from "@angular/flex-layout";

/* Components */
import { LogInComponent } from './components/log-in/log-in.component';
import { dashboardComponent } from './components/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { LoggedinService } from './logged-in.service';
import { AuthGuard } from './auth.guard';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DefaultLayoutComponent } from './@theme/default-layout/default-layout.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgotPassword.component';
import { ListmonkComponent } from './components/listmonk/listmonk.component';
import { ActivityComponent } from './components/activity/activity.component'
import { ActivityDialogComponent } from './components/activity-dialog/activity-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageActivityComponent } from './components/message-activity/message-activity.component';

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    dashboardComponent,
    RegisterComponent,
    ConfigurationComponent,
    DefaultLayoutComponent,
    ForgotPasswordComponent,
    ListmonkComponent,
    ActivityComponent,
    ActivityDialogComponent,
    MessageActivityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatDialogModule,
  ],
  providers: [LoggedinService, AuthGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }
