import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../user.service'
import { ActivatedRoute } from '@angular/router';
import { LoggedinService } from 'src/app/logged-in.service';
import { HttpClient } from '@angular/common/http';
import { BackendService } from '../../backend.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit{
  showFiller = false;
  isLoggedIn: boolean;
  userData: any;
  userName: string;
  userId: string;
  flag: boolean = false;
  fileName: any;
  filelinks: string;

  isSmallScreen = window.innerWidth < 450;
  isLargeScreen = window.innerWidth >= 950;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isSmallScreen = window.innerWidth < 450;
    this.isLargeScreen = window.innerWidth >= 950;
  }

  constructor(private fb: FormBuilder,
    private userService : UserService,
    public loggedInService: LoggedinService,
    private router : Router,
    private backendService : BackendService,
    private route: ActivatedRoute,
    private http: HttpClient) {}

  ngOnInit(): void {
    this.toggleSidebar();
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.userId = userData.userId;
    // if (userData && userData.data.name) {
    //   this.userName = capitalizeFirstLetter(userData.data.name);
    // } else {
    //   this.userName = 'User';
    // }
    // function capitalizeFirstLetter(string) {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // }
      this.backendService.getProfileImageName(this.userId).subscribe(
        data => {
          if (data['fileName'] != null) {
            this.flag = true;
            this.fileName = data['fileName'];
            this.filelinks = environment.apiUrl+'api/dashboard/getProfileImage?id=' + this.userId + "&fileName=" + this.fileName;
            this.fetchProfileImage(this.filelinks);
          } else {
            this.flag = false;
          }
        },
        error => {
          console.error('Error fetching profile image:', error);
          this.flag = false;
          this.filelinks = '';
        }
      );
  }

  toggleSidebar(): void {
    const sidenav = document.getElementById('sidenav');
    if (sidenav) {
      sidenav['toggle'](this.isSmallScreen);
    }
  }

  logout(){
    this.filelinks = '';
    this.fileName = '';
    this.loggedInService.logout()
    .subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      this.userService.logout();
      this.router.navigate(['/logout']);
  });
  }

  openFileInput(): void {
    const fileInput = document.getElementById('profilePictureInput');
    fileInput.click();
  }

  handleFileInput(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.uploadProfileImage(selectedFile);
    }
  }

  uploadProfileImage(file: File): void {
    this.backendService.uploadProfileImage(file, this.userId).subscribe(
      response => {
        this.ngOnInit();
      },
      error => {
        console.error('Error uploading image:', error);
      }
    );
  }

  fetchProfileImage(filelinks) {
    this.http.get(filelinks).subscribe(
      () => {
      },
      (error) => {
        if (error.status === 404) {
          this.flag = false;
          console.log(error.message);
        }
      }
    );
  }

facebook(){
  window.open('https://www.facebook.com/edulab.in/', '_blank');
}

linkedin(){
  window.open('https://www.linkedin.com/company/edulab.in/', '_blank');
}

youtube(){
  window.open('https://www.youtube.com/user/EdulabIndernship', '_blank');
}

}
