import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { User } from './user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class BackendService {
  handleError: any;
  constructor(private http: HttpClient) { }

  public R1C1(data: any): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/R1C1';
    return this.http.post<any>(url, data);
  }
  // public R1C2(data : any): Observable<any> {
  //   const url = environment.apiUrl+'dashboard/R1C2';
  //   return this.http.post<any>(url, data);
  // }
  public R1C2(data: any): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/R1C2';
    return this.http.post<any>(url, data);
  }
  public R1C3(data: any): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/R1C3';
    return this.http.post<any>(url, data);
  }
  public R2C1(data: any): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/R2C1';
    return this.http.post<any>(url, data);
  }
  public R2C2(data: any): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/R2C2';
    return this.http.post<any>(url, data);
  }
  uploadProfileImage(file: File, userId): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/uploadProfileImage';
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('image', file);
    return this.http.post<any>(url, formData);
  }
  getProfileImageName(userId: string): Observable<any> {
    const url = environment.apiUrl + 'api/dashboard/getProfileImageName';
    const params = { userId: userId.toString() };
    return this.http.get<any>(url, { params });
  }

  searchMonth(searchMonth: any) {
    const url = environment.apiUrl + `api/dashboard/searchMonth?searchMonth=${searchMonth}`;
    return this.http.get(url);
  }

  getUserData(searchTerm: string, page: number) {
    const url = environment.apiUrl + `api/configuration/getUserData?page=${page}&searchTerm=${searchTerm}`;
    return this.http.get(url);
  }

  getActivityData(searchEmail: string, page: number, startDate: any, endDate: any, searchSub: any, provider: any) {
    const url = environment.apiUrl + `api/activity/getEmailData?page=${page}&searchEmail=${searchEmail}&startDate=${startDate}&endDate=${endDate}&searchSub=${searchSub}&provider=${provider}`;
    return this.http.get(url);
  }

  downloadCsv(searchEmail: string, startDate: any, endDate: any, searchSub: any, provider: any) {
    return this.http.get(environment.apiUrl + `api/activity/getExportData?searchEmail=${searchEmail}&startDate=${startDate}&endDate=${endDate}&searchSub=${searchSub}&provider=${provider}`)
  }

  getMsgActivityData(page: number, phoneNo: Number, applicationName: string) {
    return this.http.get(environment.apiUrl + `api/activity/getMessageData?page=${page}&phoneNo=${phoneNo}&applicationName=${applicationName}`)

  }
}