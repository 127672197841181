import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggedinService } from 'src/app/logged-in.service';
import { UserService } from '../../user.service'

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  isLoggedIn: boolean;
  passwordVisible = false;
  message: any;
  messageAlert : boolean = false;
  loginForm = this.fb.group({
    usernameOrEmail: ['', Validators.required],
    password: ['']
  });
  title: string;
  alertColor: string;
  constructor(private fb: FormBuilder,
    private userService : UserService,
    public loggedInService: LoggedinService,
    private router : Router) {}

  ngOnInit(): void {
  }
  onSubmit(): void {
    const un = this.loginForm.controls['usernameOrEmail'].value;
    //const pwd = this.loginForm.controls['password'].value;
    if(un) {
      this.userService.login({username : un }).subscribe((response: any) => {
        if(response.status === 200){
          this.messageAlert = true;
          this.alertColor = "success"
          this.title = "Hooray!"
          this.message = "Successfully Login !!!";
          this.loggedInService.login()
          .subscribe((isLoggedIn) => {
            setTimeout(() => {
              this.messageAlert = false;
              this.isLoggedIn = isLoggedIn;
              let redirect = this.loggedInService.redirectUrl ? this.loggedInService.redirectUrl :
                  '/dashboard';
                  this.router.navigate([redirect]);
            }, 1000);
            this.userService.setUserData(response.data,response._id);
        });
        }else {
          this.messageAlert = true;
          this.alertColor = "danger";
          this.title = "";
          this.message = "Login/Username combination is not correct, please try again.";
          this.isLoggedIn = false;
          setTimeout(() => {
            this.messageAlert = false;
          }, 3000);
        }
      });
    }
    else{
      this.messageAlert = true;
      this.alertColor = "danger";
      this.title = "Oh Snap!";
      this.message = "All the fields are required.";
      this.isLoggedIn = false;
      setTimeout(() => {
        this.messageAlert = false;
      }, 3000);
    }

  }
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  redirectToPath(pathName: string) {
    this.router.navigate([`/${pathName}`]);
  }

}
