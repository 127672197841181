<mat-toolbar style="background: rgb(135, 133, 255);">
  <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="toolbar-item-spacer"></span>
  <img *ngIf="isLargeScreen" src="../../../assets/image/edulab.png" class="sidebarImage" alt="Edulab Logo"
    style="margin-right: 145vh; height: 150px; width:500px;">
  <button mat-button [matMenuTriggerFor]="beforeMenu">
    <div class="userProPicWithArrow">
      <div>
        <img *ngIf="flag === false" mat-card-avatar class="userProfilePic" src="../../../assets/image/user.png"
          alt="No Profile">
        <img *ngIf="flag === true" mat-card-avatar class="userProfilePic" [src]="filelinks" alt="User Profile">
      </div>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </button>

  <mat-menu #beforeMenu="matMenu" [overlapTrigger]="false" xPosition="before">
    <input type="file" id="profilePictureInput" style="display: none" (change)="handleFileInput($event)">
    <button mat-menu-item (click)="openFileInput()">
      <mat-icon>cloud_upload</mat-icon>
      <span>Change Profile Picture</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

</mat-toolbar>

<mat-sidenav-container class="app-container" autosize>
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="app-sidenav" [opened]="!isSmallScreen">
    <mat-divider></mat-divider>
    <mat-nav-list>
      <mat-list-item routerLink="/dashboard" routerLinkActive="active" matTooltip="Dashboard"
        matTooltipPosition="right">
        <mat-icon>dashboard</mat-icon><span>Dashboard</span>
      </mat-list-item>

      <mat-list-item routerLink="/configuration" routerLinkActive="active" matTooltip="Configuration"
        matTooltipPosition="right">
        <mat-icon>settings</mat-icon><span>Settings</span>
      </mat-list-item>

      <a href="https://email.edulab.in/" target="_blank" matTooltip="Listmonk" matTooltipPosition="right">
        <mat-list-item>
          <mat-icon>listmonk</mat-icon>
          <span>Listmonk</span>
        </mat-list-item>
      </a>

      <mat-list-item routerLink="/activity" routerLinkActive="active" matTooltip="Email" matTooltipPosition="right">
        <mat-icon>email</mat-icon>
        <span>Email Activity</span>
      </mat-list-item>

      <mat-list-item routerLink="/message" routerLinkActive="active" matTooltip="Message Activity"
        matTooltipPosition="right">
        <mat-icon>textsms</mat-icon>
        <span>Message Activity</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav-container>
  <div class="footer-content">
    <div class="social-media-icon">
      <i class="fa-brands fa-facebook" (click)="facebook()"></i>&nbsp;
      <i class="fab fa-linkedin-in" (click)="linkedin()"></i>&nbsp;
      <i class="fa-brands fa-youtube" (click)="youtube()"></i>&nbsp;
      <i class="fa-brands fa-instagram"></i>
    </div>
    <div class="footer-text">
      <p #footer>
        Created with <span style="color: red;">&#10084;</span> &nbsp;by<b>&nbsp;
          <a href="https://edulab.in" target="_blank">Edulab</a></b>
      </p>
    </div>
  </div>
</mat-sidenav-container>