import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogInComponent } from './components/log-in/log-in.component';
import { RegisterComponent } from './components/register/register.component';
import { dashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DefaultLayoutComponent } from './@theme/default-layout/default-layout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgotPassword.component';
import { ListmonkComponent } from './components/listmonk/listmonk.component';
import { ActivityComponent } from './components/activity/activity.component'
import { MessageActivityComponent } from './components/message-activity/message-activity.component'
const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LogInComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: dashboardComponent },
      { path: 'configuration', component: ConfigurationComponent },
      { path: 'listmonk', component: ListmonkComponent },
      { path: 'activity', component: ActivityComponent },
      { path: 'message', component: MessageActivityComponent }
    ],
  },
  // { path: 'dashboard', component: dashboardComponent ,canActivate:[AuthGuard]},
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
