import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BackendService } from '../../backend.service'
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import moment from "moment-es6";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivityDialogComponent } from '../activity-dialog/activity-dialog.component';
import { saveAs } from 'file-saver';

const today = moment(); // Current date
const todayFormatted = today.format('MM-DD-YYYY'); // Format current date
const prevWeek = today.subtract(7, 'days'); // Subtract 7 days from current date
const prevWeekFormatted = Date; // Format 7 days before

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})


export class ActivityComponent implements OnInit {
  userId: string;
  userData: string;
  getallUserData: string;
  filterEmail: string;
  searchedEmailData: string;
  displayedColumns: string[] = ['to', 'subject', 'provider', 'failCount', 'failTime', 'ApplicationName'];
  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  displayText: string = ''
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  count: number;
  showData: boolean = true;

  pageEvent: PageEvent;
  messageService: any;
  filepath: any;
  startDate: any;
  endDate: any;
  searchEmail: any;
  searchSub: any;
  provider: any;
  isSearchButtonClicked: boolean = false;
  isLoading: boolean = false;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getActivityData(this.searchEmail, this.pageIndex + 1, this.startDate, this.endDate, this.searchSub, this.provider);
  }

  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalItems: any;

  constructor(
    private backendService: BackendService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.userId = userData.userId;
    this.setDisplayText()
  }

  setDisplayText() {
    this.displayText = ' Find a Needle in the HayStack';
  }

  getActivityData(searchEmail: string, page: number, startDate: any, endDate: any, searchSub: any, provider: any) {
    this.searchEmail = searchEmail;
    this.showData = false;
    this.startDate = startDate;
    this.endDate = endDate;
    this.searchSub = searchSub;
    this.provider = provider;
    this.backendService.getActivityData(searchEmail, page, startDate, endDate, searchSub, provider).subscribe(data => {
      this.dataSource = data['data'].data;
      this.count = data['data'].count;
      this.searchedEmailData = data['data'].emailData;
    });
  }

  download(searchEmail: string, startDate: any, endDate: any, searchSub: any, provider: any) {
    this.searchEmail = searchEmail;
    this.showData = false;
    this.startDate = startDate;
    this.endDate = endDate;
    this.searchSub = searchSub;
    this.provider = provider;
    this.backendService.downloadCsv(searchEmail, startDate, endDate, searchSub, provider).subscribe((data) => {
      window.open(data['data'], '_blank');
    },
      (error) => {
        console.error('Error during download:', error);
      }
    );
  }

  openDialog(row: any) {
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      data: {
        id: row.id,
        status: row.status,
        to: row.to,
        from: row.from,
        shootTime: row.shootTime,
        subject: row.subject,
        viewTime: row.viewTime,
        cc: row.cc,
        bcc: row.bcc
      },
      width: '600px',
    })
    dialogRef.afterClosed().subscribe(data => {
      console.log(`Dialog result: ${data}`);
    });
  }

  search(searchEmail: string, startDate: any, endDate: any, searchSub: any, provider: any) {
    this.isSearchButtonClicked = true;
    this.isLoading = true;
    // Validate input or ensure the search criteria are provided
    if (!searchEmail && !startDate && !endDate && !searchSub && !provider) {
      this.isLoading = false;
      this.isSearchButtonClicked = false;
      alert('Please select appropriate search criteria.');
      return;
    }
    setTimeout(() => {
      this.getActivityData(searchEmail, 1, startDate, endDate, searchSub, provider);
      this.isLoading = false;
    }, 2000)
  }
}
