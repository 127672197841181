import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Utils } from 'chart.js';
import { BackendService } from '../../backend.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import moment from "moment-es6";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { MatInputModule } from '@angular/material/input';

const today = moment(); // Current date
const todayFormatted = today.format('MM-DD-YYYY'); // Format current date
const prevWeek = today.subtract(7, 'days'); // Subtract 7 days from current date
const prevWeekFormatted = prevWeek.format('MM-DD-YYYY'); // Format 7 days before

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})




export class dashboardComponent {
  range = new FormGroup({
    start: new FormControl(new Date(prevWeekFormatted), [Validators.required]),
    end: new FormControl(new Date(todayFormatted), [Validators.required])
  });
  rangeR1C2 = new FormGroup({
    start: new FormControl(new Date(prevWeekFormatted), [Validators.required]),
    end: new FormControl(new Date(todayFormatted), [Validators.required])
  });
  rangeR1C3 = new FormGroup({
    start: new FormControl(new Date(prevWeekFormatted), [Validators.required]),
    end: new FormControl(new Date(todayFormatted), [Validators.required])
  })
  rangeR2C1 = new FormGroup({
    start: new FormControl(new Date(prevWeekFormatted), [Validators.required]),
    end: new FormControl(new Date(todayFormatted), [Validators.required])
  })
  rangeR2C2 = new FormGroup({
    start: new FormControl(new Date(prevWeekFormatted), [Validators.required]),
    end: new FormControl(new Date(todayFormatted), [Validators.required])
  })
  position = new FormControl('right');
  startdate: any;
  enddate: any;
  rangeDates: any;
  datePipe: any;
  chart1: any;
  chart1Clicked: boolean = false
  clickedChart2: boolean = false;
  chart2Clicked: boolean;
  chart4: any;
  chart5: any;
  chartMessageR2C1: any;
  chartMessageR2C2: any;
  chartEmailR2C1: any;
  chartWhatsappR2C1: any;
  chartEmailR1C2: any;
  chartMessageR1C2: any;
  chartWhatsappR1C2: any;
  totalEmailsR1C1: number;
  totalMessagesR1C1: number;
  totalWhatsappMessagesR1C1: number;
  panelOpenState = false;
  chartEmailR2C2: any;
  chartWhatsappR2C2: any;
  username: string;
  shortUserName: string;
  selectedFile: File | undefined;
  userId: string;
  flag: boolean = false;
  fileName: any;
  filelinks: string;
  selected = new FormControl(0);
  initialValue: any;
  endValue: any;
  chart2: any;
  noChartR2C1Boolean: boolean = false;
  chartR2C1Boolean: boolean = false;
  noChartR2C2Boolean: boolean = false;
  chartR2C2Boolean: boolean = false;
  noChartR1C2Boolean: boolean = false;
  chartR1C2Boolean: boolean = false;
  noChartR1C3Boolean: boolean = false;
  chartR1C3Boolean: boolean = false;
  date = new FormControl(moment());
  email: any;
  ctrlValue: any;
  constructor(private backendService: BackendService, private route: ActivatedRoute, private http: HttpClient) {

  }
  ngOnInit() {
    this.DateRange();
    this.DateRange1();
  }

  tabClick(tabId) {
    if (tabId == 0) {
      this.DateRange1();
    } else if (tabId == 1) {
      this.DateRange2();
    } else if (tabId == 2) {
      this.dateRangeR2C1();
    } else if (tabId == 3) {
      this.dateRangeR2C2();
    }
  }

  private updateFormGroupDateRange(formGroup: FormGroup) {
    formGroup.patchValue({
      start: this.range.value.start,
      end: this.range.value.end,
    });
  }

  dateFilters = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }

    // Allow only the 1st day of each month
    return date.getDate() === 1;
  };
  @ViewChild('dp') datepicker: any;
  // Function to handle datepicker input
  month_year: Date | null = null;
  maxDate: Date | null = null; // Declare maxDate property here

  // Custom filter to allow only the selection of the 1st day of each month
  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    return date.getDate() === 1;
  };

  selectedMonth = new FormControl(moment());

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.ctrlValue = this.selectedMonth.value ?? moment();
    this.ctrlValue.month(normalizedMonthAndYear.month());
    this.ctrlValue.year(normalizedMonthAndYear.year());
    this.selectedMonth.setValue(this.ctrlValue);
    datepicker.close();
  }

  searchMonth() {
    const start = moment(this.ctrlValue, 'YYYY-MM').startOf('month').toDate();
    const end = moment(this.ctrlValue, 'YYYY-MM').endOf('month').toDate();
    let range = { start: start, end: end }
    if (this.ctrlValue) {
      this.backendService.R1C1(range).subscribe((response: any) => {
        this.totalEmailsR1C1 = response.email;
        this.totalMessagesR1C1 = response.sms;
        this.totalWhatsappMessagesR1C1 = response.whatsapp;
        this.ctrlValue = '';
      });
    }
  }

  DateRange() {
    this.updateFormGroupDateRange(this.rangeR1C2);
    this.updateFormGroupDateRange(this.rangeR1C3);
    this.updateFormGroupDateRange(this.rangeR2C1);
    this.updateFormGroupDateRange(this.rangeR2C2);
    let range = { start: this.range.value.start, end: this.range.value.end }
    if (this.range.value) {
      this.backendService.R1C1(range).subscribe((response: any) => {
        this.totalEmailsR1C1 = response.email;
        this.totalMessagesR1C1 = response.sms;
        this.totalWhatsappMessagesR1C1 = response.whatsapp;
      });
    }
  }

  DateRange1() {
    this.chartR1C2Boolean = true;
    this.noChartR1C2Boolean = false;
    if (this.chart1) {
      this.chart1.destroy();
    }
    if (this.chartEmailR1C2) {
      this.chartEmailR1C2.destroy();
    }
    if (this.chartMessageR1C2) {
      this.chartMessageR1C2.destroy();
    }
    if (this.chartWhatsappR2C1) {
      this.chartWhatsappR1C2.destroy();
    }
    this.backendService.R1C2(this.rangeR1C2.value).subscribe((response: any) => {

      //const { guattestation, gumigration, gupdc, guverification, guconvocation} = response;
      const { sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab } = response;
      if ([sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab].every(count => count === 0)) {
        this.noChartR1C2Boolean = true;
        this.chartR1C2Boolean = false;
        const ctx1 = (document.getElementById('chart1') as HTMLCanvasElement).getContext('2d');
        ctx1.clearRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);
        ctx1.font = '20px Arial';
        ctx1.fillStyle = 'black';
        ctx1.textAlign = 'center';
        ctx1.fillText('No Data Found', ctx1.canvas.width / 2, ctx1.canvas.height / 2);
        return;
      }
      const data = {
        //labels: ['GuAttestation', 'GuMigration', 'GuPdc', 'GuVerification', 'GuConvocation'],
        labels: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        datasets: [{
          data: [sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }]
      };

      const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart, legend, options) {
          const fitValue = chart.legend.fit;
          chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            return this.height += 20;
          }
        }
      }

      const ctx1 = (document.getElementById('chart1') as HTMLCanvasElement).getContext('2d');
      this.chart1 = new Chart(ctx1, {
        type: 'pie',
        lable: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Click one portal to see full details',
            position: 'top',
          },
          legend: {
            display: true,
            position: 'top',
            align: 'center',
          },
          onClick: (event, segments) => this.handlePieR1C2(event, segments, response),
        },
        plugins: [legendMargin]
      });
    });
  }

  DateRange2() {
    this.chartR1C3Boolean = true;
    this.noChartR1C3Boolean = false;
    if (this.chart2) {
      this.chart2.destroy();
    }
    this.backendService.R1C3(this.rangeR1C3.value).subscribe((response: any) => {
      const { sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab } = response;
      if ([sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab].every(count => count === 0)) {
        this.noChartR1C3Boolean = true;
        this.chartR1C3Boolean = false;
        const ctx1 = (document.getElementById('chart2') as HTMLCanvasElement).getContext('2d');
        ctx1.clearRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);
        ctx1.font = '16px Arial';
        ctx1.fillStyle = 'black';
        ctx1.textAlign = 'center';
        ctx1.fillText('No Data Found', ctx1.canvas.width / 2, ctx1.canvas.height / 2);
        return;
      }
      const data = {
        labels: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        datasets: [{
          label: 'Portal Count',
          data: [sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)'
          ],
          borderWidth: 1
        }]
      };

      const ctx1 = (document.getElementById('chart2') as HTMLCanvasElement).getContext('2d');
      this.chart2 = new Chart(ctx1, {
        type: 'bar',
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          },
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: true,
            text: 'Total Number of Services used by Each Portal',
            position: 'bottom',
            borderWidth: 20,
            borderRadius: 2,
            hoverBorderWidth: 0,
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        }
      });
    })
  }

  handlePieR1C2(event, segments, response) {
    this.chart2Clicked = true;
    const ctx2 = (document.getElementById('chartEmailR1C2') as HTMLCanvasElement).getContext('2d');
    const test02 = (document.getElementById('chartMessageR1C2') as HTMLCanvasElement).getContext('2d');
    const test4 = (document.getElementById('chartWhatsappR1C2') as HTMLCanvasElement).getContext('2d');

    if (segments.length > 0) {
      const clickedSegmentIndex = segments[0]._index;
      const portal = Object.keys(response)[clickedSegmentIndex];
      const portalCounts = response[portal];
      const providerCounts = response.providercounts;
      const messageCounts = response.messagecounts;
      const whatsappcount = response.whatsappcount;

      const portalLabels = {
        sdbiupdates: 'SdbiUpdates',
        sfcstudentscenter: 'SfcStudentsCenter',
        hsncadmissiondesk: 'HsncAdmissionDesk',
        pahsuadmissiondesk: 'PahsuAdmissionDesk',
        muattestation: 'MuAttestation',
        noreplystudentscenter: 'NoReplyStudentscenter',
        hrmsedulab: 'HrmsEdulab'
      };

      const newData = {
        labels: ['SendGrid', 'SendinBlue', 'Gmail', 'Nodemailer'],
        datasets: [{
          data: providerCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };

      const newData1 = {
        labels: [
          'textguru',
          'smsindiahub',
          'smsgatewayhub',
          'shreetripada',
          'voda',
          'cilsms',
          '2factor',
          'servermsg',
        ],
        datasets: [{
          data: messageCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };

      const newData2 = {
        labels: [
          portalLabels.sdbiupdates,
          portalLabels.sfcstudentscenter,
          portalLabels.hsncadmissiondesk,
          portalLabels.pahsuadmissiondesk,
          portalLabels.muattestation,
          portalLabels.noreplystudentscenter,
          portalLabels.hrmsedulab
        ],
        datasets: [{
          data: whatsappcount,
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };

      const chartOptionsEmail = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Emails from Each Portal',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsMessage = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Messages from Each Portal',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsWhatsapp = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of WhatsApp Messages',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };

      if (ctx2 && test02 && test4) {
        if (this.chartEmailR1C2) {
          this.chartEmailR1C2.destroy();
        }
        if (this.chartMessageR1C2) {
          this.chartMessageR1C2.destroy();
        }
        if (this.chartWhatsappR1C2) {
          this.chartWhatsappR1C2.destroy();
        }
        if (providerCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartEmailR1C2) {
            this.chartEmailR1C2.destroy();
          }
          ctx2.clearRect(0, 0, ctx2.canvas.width, ctx2.canvas.height);
          ctx2.font = '20px Arial';
          ctx2.fillStyle = 'black';
          ctx2.textAlign = 'center';
          ctx2.fillText('No Emails Found', ctx2.canvas.width / 2, ctx2.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartEmailR1C2) {
            this.chartEmailR1C2.destroy();
          }
          this.chartEmailR1C2 = new Chart(ctx2, { type: 'pie', data: newData, options: chartOptionsEmail });
        }
        if (messageCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartMessageR1C2) {
            this.chartMessageR1C2.destroy();
          }
          test02.clearRect(0, 0, test02.canvas.width, test02.canvas.height);
          test02.font = '20px Arial';
          test02.fillStyle = 'black';
          test02.textAlign = 'center';
          test02.fillText('No Messages Found', test02.canvas.width / 2, test02.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartMessageR1C2) {
            this.chartMessageR1C2.destroy();
          }
          this.chartMessageR1C2 = new Chart(test02, { type: 'pie', data: newData1, options: chartOptionsMessage });
        }
        if (whatsappcount.every(count => count === 0)) {
          if (this.chartWhatsappR1C2) {
            this.chartWhatsappR1C2.destroy();
          }
          test4.clearRect(0, 0, test4.canvas.width, test4.canvas.height);
          test4.font = '20px Arial';
          test4.fillStyle = 'black';
          test4.textAlign = 'center';
          test4.fillText('No Whatsapp Messages Found', test4.canvas.width / 2, test4.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartWhatsappR1C2) {
            this.chartWhatsappR1C2.destroy();
          }
          this.chartWhatsappR1C2 = new Chart(test4, { type: 'pie', data: newData2, options: chartOptionsWhatsapp });
        }
      } else {
        console.log("Could not find canvas element with id 'chart2'");
      }
    }
  }

  generateColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const color = this.getRandomColor();
      colors.push(color);
    }
    return colors;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  dateRangeR2C1() {
    this.chartR2C1Boolean = true;
    this.noChartR2C1Boolean = false;
    if (this.chart4) {
      this.chart4.destroy();
    }
    if (this.chartEmailR2C1) {
      this.chartEmailR2C1.destroy();
    }
    if (this.chartMessageR2C1) {
      this.chartMessageR2C1.destroy();
    }
    if (this.chartWhatsappR2C1) {
      this.chartWhatsappR2C1.destroy();
    }
    this.backendService.R2C1(this.rangeR2C1.value).subscribe((response: any) => {
      const { sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab } = response;
      if ([sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab].every(count => count === 0)) {
        this.noChartR2C1Boolean = true;
        this.chartR2C1Boolean = false;
        const ctx1 = (document.getElementById('chart4') as HTMLCanvasElement).getContext('2d');
        ctx1.clearRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);
        ctx1.font = '20px Arial';
        ctx1.fillStyle = 'black';
        ctx1.textAlign = 'center';
        ctx1.fillText('No Data Found', ctx1.canvas.width / 2, ctx1.canvas.height / 2);
        return;
      }
      const data = {
        labels: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        datasets: [{
          data: [sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
          hoverOffset: 2
        }]
      };
      const ctx1 = (document.getElementById('chart4') as HTMLCanvasElement).getContext('2d');
      this.chart4 = new Chart(ctx1, {
        type: 'doughnut',
        labels: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        data: data,
        options: {
          title: {
            display: true,
            text: 'Click on a portal to see the total number of failures',
            position: 'bottom',
            borderWidth: 20,
            borderRadius: 2,
            hoverBorderWidth: 0,
            plugins: {
              legend: {
                display: false,
              },
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          onClick: (event, segments) => this.handlePieR2C1(event, segments, response)
        }
      });
    });
  }

  handlePieR2C1(event, segments, response) {
    this.chart2Clicked = true;
    const chartEmailDataR2C1 = (document.getElementById('chartEmailR2C1') as HTMLCanvasElement).getContext('2d');
    const chartMessageDataR2C1 = (document.getElementById('chartMessageR2C1') as HTMLCanvasElement).getContext('2d');
    const chartWhatsappDataR2C1 = (document.getElementById('chartWhatsappR2C1') as HTMLCanvasElement).getContext('2d');
    if (segments.length > 0) {
      const clickedSegmentIndex = segments[0]._index;
      const portal = Object.keys(response)[clickedSegmentIndex];
      const portalCounts = response[portal];
      const providerCounts = response.providercounts;
      const messageCounts = response.messagecounts;
      const whatsappcount = response.whatsappcount;

      const portalLabels = {
        sdbiupdates: 'SdbiUpdates',
        sfcstudentscenter: 'SfcStudentsCenter',
        hsncadmissiondesk: 'HsncAdmissionDesk',
        pahsuadmissiondesk: 'PahsuAdmissionDesk',
        muattestation: 'MuAttestation',
        noreplystudentscenter: 'NoReplyStudentscenter',
        hrmsedulab: 'HrmsEdulab'
      };
      const newData = {
        labels: ['SendGrid', 'SendinBlue', 'Gmail', 'Nodemailer'],
        datasets: [{
          data: providerCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const newData1 = {
        labels: [
          'textguru',
          'smsindiahub',
          'smsgatewayhub',
          'shreetripada',
          'voda',
          'cilsms',
          '2factor',
          'servermsg',
        ],
        datasets: [{
          data: messageCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const newData2 = {
        labels: [
          portalLabels.sdbiupdates,
          portalLabels.sfcstudentscenter,
          portalLabels.hsncadmissiondesk,
          portalLabels.pahsuadmissiondesk,
          portalLabels.muattestation,
          portalLabels.noreplystudentscenter,
          portalLabels.hrmsedulab
        ],
        datasets: [{
          data: whatsappcount,
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const chartOptionsEmail = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of Email Failed',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsMessage = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of Message Failed',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsWhatsapp = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of WhatsApp Failed',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      if (chartEmailDataR2C1 && chartMessageDataR2C1 && chartWhatsappDataR2C1) {
        if (this.chartEmailR2C1) {
          this.chartEmailR2C1.destroy();
        }
        if (this.chartMessageR2C1) {
          this.chartMessageR2C1.destroy();
        }
        if (this.chartWhatsappR2C1) {
          this.chartWhatsappR2C1.destroy();
        }
        if (providerCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartEmailR2C1) {
            this.chartEmailR2C1.destroy();
          }
          chartEmailDataR2C1.clearRect(0, 0, chartEmailDataR2C1.canvas.width, chartEmailDataR2C1.canvas.height);
          chartEmailDataR2C1.font = '20px Arial';
          chartEmailDataR2C1.fillStyle = 'black';
          chartEmailDataR2C1.textAlign = 'center';
          chartEmailDataR2C1.fillText('No Emails Failed', chartEmailDataR2C1.canvas.width / 2, chartEmailDataR2C1.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartEmailR2C1) {
            this.chartEmailR2C1.destroy();
          }
          this.chartEmailR2C1 = new Chart(chartEmailDataR2C1, { type: 'pie', data: newData, options: chartOptionsEmail });
        }
        if (messageCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartMessageR2C1) {
            this.chartMessageR2C1.destroy();
          }
          chartMessageDataR2C1.clearRect(0, 0, chartMessageDataR2C1.canvas.width, chartMessageDataR2C1.canvas.height);
          chartMessageDataR2C1.font = '20px Arial';
          chartMessageDataR2C1.fillStyle = 'black';
          chartMessageDataR2C1.textAlign = 'center';
          chartMessageDataR2C1.fillText('No Messages Failed', chartMessageDataR2C1.canvas.width / 2, chartMessageDataR2C1.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartMessageR2C1) {
            this.chartMessageR2C1.destroy();
          }
          this.chartMessageR2C1 = new Chart(chartMessageDataR2C1, { type: 'pie', data: newData1, options: chartOptionsMessage });
        }
        if (whatsappcount.every(count => count === 0)) {
          if (this.chartWhatsappR2C1) {
            this.chartWhatsappR2C1.destroy();
          }
          chartWhatsappDataR2C1.clearRect(0, 0, chartWhatsappDataR2C1.canvas.width, chartWhatsappDataR2C1.canvas.height);
          chartWhatsappDataR2C1.font = '20px Arial';
          chartWhatsappDataR2C1.fillStyle = 'black';
          chartWhatsappDataR2C1.textAlign = 'center';
          chartWhatsappDataR2C1.fillText('No Whatsapp Messages Failed', chartWhatsappDataR2C1.canvas.width / 2, chartWhatsappDataR2C1.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartWhatsappR2C1) {
            this.chartWhatsappR2C1.destroy();
          }
          this.chartWhatsappR2C1 = new Chart(chartWhatsappDataR2C1, { type: 'pie', data: newData2, options: chartOptionsWhatsapp });
        }
      } else {
        console.log("Could not find canvas element with id 'chart2'");
      }
    }
  }

  dateRangeR2C2() {
    this.chartR2C2Boolean = true;
    this.noChartR2C2Boolean = false;
    if (this.chart5) {
      this.chart5.destroy();
    }
    if (this.chartEmailR2C2) {
      this.chartEmailR2C2.destroy();
    }
    if (this.chartMessageR2C2) {
      this.chartMessageR2C2.destroy();
    }
    if (this.chartWhatsappR2C2) {
      this.chartWhatsappR2C2.destroy();
    }
    this.backendService.R2C2(this.rangeR2C2.value).subscribe((response: any) => {
      const { sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab } = response;
      if ([sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab].every(count => count === 0)) {
        this.noChartR2C2Boolean = true;
        this.chartR2C2Boolean = false;
        const ctx1 = (document.getElementById('chart5') as HTMLCanvasElement).getContext('2d');
        ctx1.clearRect(0, 0, ctx1.canvas.width, ctx1.canvas.height);
        ctx1.font = '20px Arial';
        ctx1.fillStyle = 'black';
        ctx1.textAlign = 'center';
        ctx1.fillText('No Data Found', ctx1.canvas.width / 2, ctx1.canvas.height / 2);
        return;
      }
      const data = {
        labels: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        datasets: [{
          data: [sdbiupdates, sfcstudentscenter, hsncadmissiondesk, pahsuadmissiondesk, muattestation, noreplystudentscenter, hrmsedulab],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }]
      };
      const ctx1 = (document.getElementById('chart5') as HTMLCanvasElement).getContext('2d');
      this.chart5 = new Chart(ctx1, {
        type: 'polarArea',
        lable: ['SdbiUpdates', 'SfcStudentsCenter', 'HsncAdmissionDesk', 'PahsuAdmissionDesk', 'MuAttestation', 'NoReplyStudentscenter', 'HrmsEdulab'],
        data: data,
        options: {
          title: {
            display: true,
            text: 'Click on a portal to see the top Providers',
            position: 'bottom',
            borderWidth: 20,
            borderRadius: 2,
            hoverBorderWidth: 0,
            plugins: {
              legend: {
                display: false,
              },
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          onClick: (event, segments) => this.handlePieR2C2(event, segments, response)
        }
      });
    });
  }


  handlePieR2C2(event, segments, response) {
    this.chart2Clicked = true;
    const chartEmailDataR2C2 = (document.getElementById('chartEmailR2C2') as HTMLCanvasElement).getContext('2d');
    const chartMessageDataR2C2 = (document.getElementById('chartMessageR2C2') as HTMLCanvasElement).getContext('2d');
    const chartWhatsappDataR2C2 = (document.getElementById('chartWhatsappR2C2') as HTMLCanvasElement).getContext('2d');
    if (segments.length > 0) {
      const clickedSegmentIndex = segments[0]._index;
      const portal = Object.keys(response)[clickedSegmentIndex];
      const portalCounts = response[portal];
      const providerCounts = response.providercounts;
      const messageCounts = response.messagecounts;
      const whatsappcount = response.whatsappcount;
      const portalLabels = {
        sdbiupdates: 'SdbiUpdates',
        sfcstudentscenter: 'SfcStudentsCenter',
        hsncadmissiondesk: 'HsncAdmissionDesk',
        pahsuadmissiondesk: 'PahsuAdmissionDesk',
        muattestation: 'MuAttestation',
        noreplystudentscenter: 'NoReplyStudentscenter',
        hrmsedulab: 'HrmsEdulab'
      };
      const newData = {
        labels: ['SendGrid', 'SendinBlue', 'Gmail', 'Nodemailer'],
        datasets: [{
          data: providerCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const newData1 = {
        labels: [
          'textguru',
          'smsindiahub',
          'smsgatewayhub',
          'shreetripada',
          'voda',
          'cilsms',
          '2factor',
          'servermsg',
        ],
        datasets: [{
          data: messageCounts[clickedSegmentIndex],
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const newData2 = {
        labels: [
          portalLabels.sdbiupdates,
          portalLabels.sfcstudentscenter,
          portalLabels.hsncadmissiondesk,
          portalLabels.pahsuadmissiondesk,
          portalLabels.muattestation,
          portalLabels.noreplystudentscenter,
          portalLabels.hrmsedulab
        ],
        datasets: [{
          data: whatsappcount,
          backgroundColor: [
            'rgb(0, 153, 255)',
            'rgb(255, 128, 170)',
            'rgb(0, 255, 0)',
            'rgb(204, 51, 255)',
            'rgb(0, 0, 255)',
            'rgb(255, 0, 0)',
            'rgb(255, 102, 0)',
            'rgb(0, 51, 153)',
          ],
        }],
      };
      const chartOptionsEmail = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of Email',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsMessage = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of Messages',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      const chartOptionsWhatsapp = {
        responsive: true,
        title: {
          display: true,
          text: 'Total Number of WhatsApp Messages',
          position: 'bottom',
        },
        legend: {
          display: false
        }
      };
      if (chartEmailDataR2C2 && chartMessageDataR2C2 && chartWhatsappDataR2C2) {
        if (this.chartEmailR2C2) {
          this.chartEmailR2C2.destroy();
        }
        if (this.chartMessageR2C2) {
          this.chartMessageR2C2.destroy();
        }
        if (this.chartWhatsappR2C2) {
          this.chartWhatsappR2C2.destroy();
        }
        if (providerCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartEmailR2C2) {
            this.chartEmailR2C2.destroy();
          }
          chartEmailDataR2C2.clearRect(0, 0, chartEmailDataR2C2.canvas.width, chartEmailDataR2C2.canvas.height);
          chartEmailDataR2C2.font = '20px Arial';
          chartEmailDataR2C2.fillStyle = 'black';
          chartEmailDataR2C2.textAlign = 'center';
          chartEmailDataR2C2.fillText('No Emails Found', chartEmailDataR2C2.canvas.width / 2, chartEmailDataR2C2.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartEmailR2C2) {
            this.chartEmailR2C2.destroy();
          }
          this.chartEmailR2C2 = new Chart(chartEmailDataR2C2, { type: 'doughnut', data: newData, options: chartOptionsEmail });
        }
        if (messageCounts[clickedSegmentIndex].every(count => count === 0)) {
          if (this.chartMessageR2C2) {
            this.chartMessageR2C2.destroy();
          }
          chartMessageDataR2C2.clearRect(0, 0, chartMessageDataR2C2.canvas.width, chartMessageDataR2C2.canvas.height);
          chartMessageDataR2C2.font = '20px Arial';
          chartMessageDataR2C2.fillStyle = 'black';
          chartMessageDataR2C2.textAlign = 'center';
          chartMessageDataR2C2.fillText('No Messages Found', chartMessageDataR2C2.canvas.width / 2, chartMessageDataR2C2.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartMessageR2C2) {
            this.chartMessageR2C2.destroy();
          }
          this.chartMessageR2C2 = new Chart(chartMessageDataR2C2, { type: 'doughnut', data: newData1, options: chartOptionsMessage });
        }
        if (whatsappcount.every(count => count === 0)) {
          if (this.chartWhatsappR2C2) {
            this.chartWhatsappR2C2.destroy();
          }
          chartWhatsappDataR2C2.clearRect(0, 0, chartWhatsappDataR2C2.canvas.width, chartWhatsappDataR2C2.canvas.height);
          chartWhatsappDataR2C2.font = '20px Arial';
          chartWhatsappDataR2C2.fillStyle = 'black';
          chartWhatsappDataR2C2.textAlign = 'center';
          chartWhatsappDataR2C2.fillText('No Whatsapp Messages Found', chartWhatsappDataR2C2.canvas.width / 2, chartWhatsappDataR2C2.canvas.height / 2);
        } else if (messageCounts[clickedSegmentIndex].length > 0) {
          if (this.chartWhatsappR2C2) {
            this.chartWhatsappR2C2.destroy();
          }
          this.chartWhatsappR2C2 = new Chart(chartWhatsappDataR2C2, { type: 'doughnut', data: newData2, options: chartOptionsWhatsapp });
        }
      } else {
        console.log("Could not find canvas element with id 'chart2'");
      }
    }
  }
}
