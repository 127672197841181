import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/backend.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@NgModule({
  imports: [

    // other imports
  ],
  // other properties
})
export class AppModule { }


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-message-activity',
  templateUrl: './message-activity.component.html',
  styleUrls: ['./message-activity.component.css']
})
export class MessageActivityComponent implements OnInit {

  isSearchButtonClicked: boolean = false;
  displayedColumns: string[] = ['PhoneNo', 'message', 'shootTime', 'failCount', 'failTime', 'ApplicationName'];
  phoneNo: any;
  applicationName: any;
  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  displayText: string = ''
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  count: number;
  showData: boolean = true;
  pageEvent: PageEvent;
  isLoading: boolean = false;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getActivityData(this.pageIndex + 1, this.phoneNo, this.applicationName);
  }
  dataSource: MatTableDataSource<PeriodicElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalItems: any;
  constructor(
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    this.setDisplayText();
  }

  setDisplayText() {
    this.displayText = 'Find a Needle in the HayStack';
  }
  getActivityData(page: number, phoneNo: Number, applicationName: string) {
    this.phoneNo = phoneNo;
    this.applicationName = applicationName;
    this.backendService.getMsgActivityData(page, phoneNo, applicationName).subscribe(data => {
      this.dataSource = data['data'].searchData;
      this.count = data['data'].count;
    })
  }

  search(phoneNo: any, applicationName: any) {
    this.isSearchButtonClicked = true;
    this.isLoading = true;

    // Validate input or ensure the search criteria are provided
    if (!phoneNo && !applicationName) {
      this.isLoading = false;
      this.isSearchButtonClicked = false;
      alert('Please select appropriate search criteria.');
      return;
    }
    setTimeout(() => {
      this.getActivityData(1, phoneNo, applicationName);
      this.isLoading = false;
      this.showData = false;
    }, 2000);
  }
}